<template>
  <div />
</template>

<script>
import AbstractPage from 'chimera/all/components/page/AbstractPage.vue'

export default {
  name: 'AbstractHomePage',

  extends: AbstractPage,

  /**
   * When landing on an homepage the configuration landing page resets.
   */
  created() {
    this.$store.dispatch('configuration/resetLandingPage')
  },
}
</script>
