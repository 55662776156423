const homePageName = 'index'

const baseFormRouteNl = '/offertes-aanvragen'
const baseFormRouteFr = '/quotes-requests'
const baseFormRouteEs = '/solicitudes-de-cotizacion'
const baseFormRouteDe = '/angebotsanfragen'
const baseFormRouteIt = '/richieste-di-preventivi'

const baseFormRoutesMap = [
  baseFormRouteNl,
  baseFormRouteFr,
  baseFormRouteEs,
  baseFormRouteDe,
  baseFormRouteIt,
]

/**
 *
 * @param {string} routePath
 * @returns {boolean}
 */
const searchRoutesInString = (routePath) => {
  return baseFormRoutesMap.some((route) => routePath.includes(route))
}

export {
  baseFormRouteNl,
  homePageName,
  baseFormRoutesMap,
  searchRoutesInString,
}
