var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate-inputs max-w-[280px]"},[_c('div',{staticClass:"flex justify-center w-full"},[_c('div',{staticClass:"flex gap-x-2"},_vm._l((_vm.inputsMapping),function(input,index){return _c('input',{key:index,ref:index,refInFor:true,staticClass:"input",class:{
          'border-error text-error': _vm.errorMessages.length > 0,
          'border-success':
            _vm.isValid && _vm.inputsValue.length >= _vm.inputsMapping.length,
        },attrs:{"autofocus":index === 0,"type":input.type,"placeholder":input.placeholder,"name":`inputvalue${index}`,"autocomplete":"off","maxlength":_vm.inputsValue ? 1 : undefined},domProps:{"value":_vm.inputsValue[index]},on:{"input":_vm.onCodeInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"backspace",undefined,$event.key,undefined))return null;$event.target.value || _vm.onCodeBackspace(index)}}})}),0)]),_vm._v(" "),_c('input',{ref:"inputAutofill",staticClass:"separate-inputs__autofill",class:{
      hide: _vm.inputsValue !== '',
    },attrs:{"type":_vm.inputsMapping[0].type,"name":"postal","maxlength":_vm.inputsMapping.length},on:{"input":_vm.onInputAutofill}})])
}
var staticRenderFns = []

export { render, staticRenderFns }