import toKebabCase from 'chimera/all/functions/toKebabCase'
import { setDynamicEventScopeValue } from 'chimera/all/plugins/eventbus/scope'

export const checkIfAbstractFormPage = (componentName) =>
  componentName === 'AbstractFormPage'

const getStepNameFromPath = (inputString) =>
  toKebabCase(inputString.split('/').pop()) || '/'

const extractComponentName = (route) =>
  route.matched?.[0]?.components.default.options.name

const checkIfExitingAbstractFormPage = (fromComponent, toComponent) =>
  checkIfAbstractFormPage(fromComponent) &&
  !checkIfAbstractFormPage(toComponent)

const checkIfEnteringAbstractFormPage = (fromComponent, toComponent) =>
  !checkIfAbstractFormPage(fromComponent) &&
  checkIfAbstractFormPage(toComponent)

const SESSION_STORAGE_KEY = 'navigationHistory'
export const determineFormNavigationDirection = (fromRoute, toRoute) => {
  const history = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEY) || '[]',
  )
  const isBackward =
    history.length > 0 && history[history.length - 1] === toRoute.path

  if (isBackward) {
    history.pop()
  } else {
    history.push(fromRoute.path)
  }

  sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(history))
  return isBackward ? 'backwards' : 'forwards'
}

/**
 * Handle navigation event broadcasting.
 * @param {object} vm - The current Vue instance
 * @param {object} fromRoute - The route being navigated from
 * @param {object} toRoute - The route being navigated to
 */
const handleNavigationEvents = (vm, fromRoute, toRoute) => {
  vm.$eventBus.emitPageViewEvent()

  const fromComponent = extractComponentName(fromRoute)
  const toComponent = extractComponentName(toRoute)

  // Because a landing page or homepage usually also contains a form step with parts we consider
  // those pages to be part of the form flow for chimera. This will reliably set the form_id and step_id.
  // Because we set these on every navigation, these values are kept up-to-date and any form events
  // will always have the correct form_id and step_id linked to them.
  const landingPageUrl =
    vm.$store.getters['configuration/getLandingPage']('url')
  const formEntryIdentifier =
    vm.$store.getters['configuration/getLandingPage']('identifier')
  if (landingPageUrl === toRoute.path || checkIfAbstractFormPage(toComponent)) {
    setDynamicEventScopeValue('form_id', 'default')
    setDynamicEventScopeValue(
      'step_id',
      toRoute.path === '/' ? 'homepage' : toRoute.path.split('/')?.pop() || '/',
    )
    setDynamicEventScopeValue(
      'form_entry_point',
      formEntryIdentifier === 'index' ? 'homepage' : formEntryIdentifier,
    )
  }

  if (fromComponent === toComponent) {
    if (checkIfAbstractFormPage(fromComponent)) {
      const navDirection = determineFormNavigationDirection(fromRoute, toRoute)
      vm.$eventBus.emitFormNavigationEvent(
        navDirection,
        getStepNameFromPath(fromRoute.path),
        getStepNameFromPath(toRoute.path),
      )
    }

    return
  }

  if (checkIfExitingAbstractFormPage(fromComponent, toComponent)) {
    sessionStorage.removeItem(SESSION_STORAGE_KEY)
    vm.$eventBus.emitFormLeaveEvent(getStepNameFromPath(fromRoute.path))
    return
  }

  if (checkIfEnteringAbstractFormPage(fromComponent, toComponent)) {
    vm.$eventBus.emitFormEnterEvent(getStepNameFromPath(toRoute.path))
  }
}

export default handleNavigationEvents
